import 'intersection-observer';
import { useEffect, useState, cloneElement, useRef } from 'react';
import { v4 as UUID } from 'uuid';
import { object, any, array, string, oneOfType } from 'prop-types';
import dynamic from 'next/dynamic';
import App from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';
import nookies from 'nookies';

import UserProvider from 'context/user';
import { ErrorProvider } from 'context/error';
import I18nProvider from 'context/i18n';

import { t, newT } from 'utils/i18n';
import { getTranslations, getCountries, checkProfile, getPopUpInfo, getI18nTimeStamp, getTranslationsNew } from 'actions/general';
import { autosuggestUnbxd } from 'actions/search';
import { addToBag, getIpadCountries } from 'actions/products';
import { setBagCookies } from 'utils/bag';
import { categories, parseBagItemsEcommerceGA4 } from 'utils/gtmUtils';
import { dataLayerHandleEvent, CAMPAIGN_ID } from 'utils/dataLayers';
import {
  getCookie,
  setCookie,
  COOKIES,
  getLang,
  getMarket,
  eraseCookie,
  updateQuerystringParam,
  delayFunction,
  handleStorage,
  setCookieBonds,
  getRealID,
  filterTransformer,
  getAppliedDiscount,
  getRealLanguage,
} from 'utils/helpers';
import { I18N_LABELS } from 'utils/routes';
import { AUTO_BONDS } from 'utils/constants/cookies';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { addLazyLoadIntersector, addLazyLoadIntersectorLanding } from 'utils/lazyload';
import { logError } from 'utils/error';
import GreatBritainModal from 'components/greatBritainModal';

import 'styles/globals.css';
import 'styles/tailwind.css';
import 'styles/autosuggest.css';
import { useUtmParams } from 'utils/utm';

const CMSProvider =
  IS_CAMPER ?
    dynamic(() => import('@camper/react-web-components').then((res) => res.CMSProvider))
  : dynamic(() => import('@nnormal/react-web-components').then((res) => res.CMSProvider));

const StoresModal = dynamic(() => import('components/storesModal').then((mod) => mod.StoresModal), { ssr: false });

require('styles/antd-custom.less');

const labelsExecutor = async (labels, callback) => {
  try {
    // cuando el timeout termina, si no hay nuevas, hacemos GET de timestamp
    // aquí en base al TS haremos POST || setearemos el evento de visibilitychange para el POST
    if (labels.length > 0 && navigator?.sendBeacon) {
      const labelsGetResponse = await getI18nTimeStamp(labels);

      if (labelsGetResponse && labelsGetResponse.hash) {
        const { hash, shouldPost } = labelsGetResponse;

        const sendLabels = () => {
          const urlDomain = process.env.MISC_ENDPOINT || (IS_CAMPER ? 'https://misc.camper.com' : 'https://misc.nnormal.com');
          const labelsBlob = new Blob([JSON.stringify(labels)], { type: 'application/json' });
          navigator.sendBeacon(`${urlDomain}${I18N_LABELS}${hash}`, labelsBlob);
          return callback();
        };

        if (shouldPost) {
          return sendLabels();
        }
      }

      return callback();
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};

function MyApp({ Component, pageProps, labels, locale, countries, urlBase, environment, profile, voucher, popupInfo, prevoucher, unbxdInitialSearch }) {
  const router = useRouter();
  const { query } = router;
  useUtmParams();

  const [appmode, setAppmode] = useState(false);
  const [storeData, setStoreData] = useState();
  const [autoappliedVoucher, setAutoappliedVoucher] = useState(false);
  const [detectedCountry, setDetectedCountry] = useState(undefined);
  const [userInteracted, setUserInteracted] = useState(false);
  const market = getMarket(locale);
  const labelsToSend = useRef([]);
  const sentLabels = useRef([]);
  let sendingLabels = false;
  const { i18nPage } = Component;

  const changeUserInteraction = (event) => {
    let extraChecks = () => true;

    if (event.type === 'scroll') {
      extraChecks = () => event.currentTarget !== null;
    }

    if (userInteracted === false && extraChecks()) {
      setUserInteracted(true);
      window.removeEventListener('mousemove', changeUserInteraction);
      window.removeEventListener('scroll', changeUserInteraction);
      window.removeEventListener('touchstart', changeUserInteraction);
    }
  };

  useEffect(() => {
    const countryFromCookie = getCookie(COOKIES.DETECTED_COUNTRY);

    if (process.browser && countryFromCookie !== false && countryFromCookie?.toUpperCase !== undefined) {
      setDetectedCountry(countryFromCookie.toUpperCase());
      window.addEventListener('mousemove', changeUserInteraction);
      window.addEventListener('scroll', changeUserInteraction);
      window.addEventListener('touchstart', changeUserInteraction);
    }
    if (process.browser) {
      window.addToCartMultiple = async (productsArray, isRoku, currentBag) => {
        try {
          let cookiesBagId = getCookie(COOKIES.BAG);
          // const sizeLabel = !isAccessory ? sizes.filter((size) => size?.value === selectedSize)[0]?.label : selectedSize;
          const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
          const voucherBonds = getCookie(COOKIES.BONDS) || '';
          const voucherDiscount = IS_CAMPER && autoVoucher && autoVoucher === AUTO_BONDS.APPLY;
          let lastResult = {};
          const results = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const product of productsArray) {
            const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
            const cookiesShipment = getCookie(COOKIES.SHIPMENT);
            const cookiesBonds = getCookie(COOKIES.BONDS);
            const cookiesZip = getCookie(COOKIES.ZIP);
            const cookiesEmployeeId = getCookie(COOKIES.EMPLOYEE_ID);
            const cookiesPackstation = getCookie(COOKIES.PACKSTATION);
            const cookiesCtr = getCookie(COOKIES.IS_CTR);
            const payload = {
              ...product,
              id: product.sku,
              sizeLabel: product.size,
              profile: {
                countryId: getMarket(locale),
                languageId: getLang(locale),
              },
              thumbnail: `https://cloud.${IS_CAMPER ? 'camper' : 'nnormal'}.com/is/image/JGVzaG9wMDNiYWdncmV5JA==/${product.sku}_L.jpg`,
            };

            if (cookiesMarket !== market) {
              payload.hashOrderId = '';
              if (cookiesBagId) {
                eraseCookie(COOKIES.BAG);
                eraseCookie(COOKIES.BAG_DATE);
              }
              if (cookiesZip) {
                eraseCookie(COOKIES.ZIP);
              }
              if (cookiesShipment) {
                eraseCookie(COOKIES.SHIPMENT);
              }
              if (cookiesMarket !== null && cookiesBonds) {
                eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
                eraseCookie(COOKIES.AUTO_BONDS);
              }
              if (cookiesEmployeeId) {
                eraseCookie(COOKIES.EMPLOYEE_ID);
              }
              if (cookiesPackstation) {
                eraseCookie(COOKIES.PACKSTATION);
              }
              if (cookiesCtr) {
                eraseCookie(COOKIES.IS_CTR);
              }
            }
            if (voucherDiscount) {
              payload.voucher = voucherBonds;
            }
            if (cookiesBagId) {
              payload.hashOrderId = decodeURI(cookiesBagId);
            }

            if (isRoku) {
              payload.checkStock = true;
            }
            // eslint-disable-next-line no-await-in-loop
            const result = await addToBag(payload);

            if (['OK', 'NOSTOCK'].includes(result?.status)) {
              lastResult = result;
            }

            if (result.hashOrderId) {
              cookiesBagId = encodeURI(result.hashOrderId);
              setBagCookies({ hash: encodeURI(result.hashOrderId), market });
            } else {
              console.error('ERROR addToCartMultiple');
              console.error('Payload: ', payload);
            }

            results.push({ ...product, ...result });
          }

          if (results.filter((item) => item.status === 'OK')?.length > 0) {
            // solo hacemos un addToCartEvent si se ha añadido algo
            // con el control de roku es posible que hayamos intentado
            // añadir varios y no tener éxito con ninguno
            const addedItems = results
              .filter((item) => item.status === 'OK')
              .map((rs) => {
                const item = rs.shoppingCart[0];
                const { name, productId, price: prices, category, target, color, size } = item;
                const currentPrice = prices === null ? 0 : prices?.current;
                const currentPriceEur = prices === null ? 0 : prices?.currentEur;
                const productCategory = category?.split('/');
                const currentList = handleStorage('getItem', 'gtmCurrentList');
                const currentListSplit = currentList ? currentList.split('|') : '';
                const currentListId = currentList && Array.isArray(currentListSplit) ? currentListSplit[0] : '';
                const currentListName = currentList && Array.isArray(currentListSplit) && currentList.length > 1 ? currentListSplit[1] : '';
                const currentListUrl = handleStorage('getItem', 'gtmCurrentListUrl');
                const lastVisitedUrl = document?.referrer ? document?.referrer.split('?')[0] : null;
                const gtmIndex = handleStorage('getItem', 'gtmCurrentItemIndex');
                const itemListName = currentListUrl === lastVisitedUrl ? currentListName : '';
                const itemListId = currentListUrl === lastVisitedUrl && currentList ? currentListId.replaceAll(' ', '_') : '';

                return {
                  item_name: name?.toLowerCase() || '',
                  item_id: productId,
                  price: currentPrice,
                  price_eur: currentPriceEur,
                  price_net: prices?.netPrice ?? 0,
                  discount: getAppliedDiscount(prices),
                  discount_eur: prices?.discountEur || 0,
                  item_brand: IS_CAMPER ? 'camper' : 'nnormal',
                  item_category: target in categories ? categories[target] : '', // 'women
                  item_category2: productCategory.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
                  item_category3: productCategory.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
                  item_category4: productCategory.length >= 3 ? productCategory[3].toLowerCase() : '', // <linea>
                  item_category5: size || '', // productCategory && Array.isArray(productCategory) ? productCategory.pop() : '', // DISCOUNT-....
                  item_variant: color?.toLowerCase() || '',
                  item_list_name: itemListName || null,
                  item_list_id: itemListId?.toLowerCase(),
                  item_size: size || '',
                  quantity: 1,
                  index: gtmIndex ? Number(gtmIndex) : 1,
                };
              });

            const fullBag = currentBag?.length > 0 ? [...parseBagItemsEcommerceGA4(currentBag), ...addedItems] : [...addedItems];
            const addToCartEvent = {
              event: 'add_to_cart',
              id_bag: cookiesBagId,
              ecommerce: {
                currencyCode: profile?.currencySap || 'EUR',
                currency: profile?.currencySap || 'EUR',
                value: results
                  .filter((item) => item.status === 'OK')
                  .map((item) => item.price)
                  .reduce((acc, item) => item + acc, 0),
                items: addedItems,
              },
              cart: {
                items: fullBag,
              },
            };
            dataLayerHandleEvent(addToCartEvent);
          }
          const { sapActive, extendedBlockSAP } = lastResult;
          if (!sapActive && !extendedBlockSAP) {
            // can't pay
            window.location.assign(`${window.location.origin}/${locale}/pedido_offline`);
            return false;
          }
          if (extendedBlockSAP) {
            // can pay
            setCookie(COOKIES.SHIPMENT, '01', undefined, COOKIES.DOMAIN);
          }

          const okResults = results.filter((item) => item.status === 'OK');

          if (!isRoku) {
            window.location.assign(`${window.location.origin}/${locale}/bolsa`);
            return true;
          }

          return [okResults.length === productsArray.length, results];
        } catch (error) {
          console.error('Unexpected ERROR addToCartMultiple');
        }

        return true;
      };
    }

    try {
      const clientCookie = getCookie(COOKIES.CLIENT);

      if (!clientCookie) {
        // TODO: reemplazar con js crypto https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID y quitar libreria de uuid
        const newID = UUID();
        setCookie(COOKIES.CLIENT, newID, null);
      }
    } catch (error) {
      console.error(`Error recuperando / seteando la cookie ${COOKIES.CLIENT}`);
      console.error(error);
    }

    // Cookies de IDIOMA
    if (profile && !profile.global_e) {
      eraseCookie(COOKIES.GLOBAL_E);
    }

    if (locale !== undefined && locale.length === 5 && /^[a-z]{2}_[A-Z]{2}$/.exec(locale)) {
      setCookie(COOKIES.COUNTRY, getMarket(locale), 30, COOKIES.DOMAIN);
      setCookie(COOKIES.LANG, getLang(locale), 30, COOKIES.DOMAIN);
    }

    if (query) {
      const { appIpad, workstation, store_id, user_id } = query;

      const cookiesToSet = {
        [COOKIES.WORKSTATION]: workstation,
        [COOKIES.TPV_STORE_ID]: store_id,
        [COOKIES.TPV_EMPLOYEE_ID]: user_id,
      };

      if (appIpad === 'true') {
        setCookie(COOKIES.APP_IPAD, 'true', undefined);
      } else if (appIpad === 'false') {
        setCookie(COOKIES.APP_IPAD, 'false', undefined); // el OR en setCookie hace que no podamos pasar false directamente
      }

      Object.keys(cookiesToSet).forEach((cookieKey) => {
        const queryValue = cookiesToSet[cookieKey];

        if (queryValue) {
          setCookie(cookieKey, queryValue, undefined, COOKIES.DOMAIN);
        }
      });
    }

    const appCookie = getCookie(COOKIES.APP_IPAD);

    if (appCookie === 'false' || query?.appIpad === 'false') {
      setAppmode(false);
      updateQuerystringParam(window.location.search, 'appIpad', false, false);
    }

    if (appCookie === 'true' || query?.appIpad === 'true') {
      setAppmode(true);
      updateQuerystringParam(window.location.search, 'appIpad', true, true);
    }

    if (voucher) {
      setCookieBonds(voucher);
      setCookie(COOKIES.AUTO_BONDS, '1', COOKIES.EXPIRATION_24H, COOKIES.DOMAIN);
      setAutoappliedVoucher(true);
    }

    if (prevoucher) {
      setCookie(COOKIES.PREVOUCHER, prevoucher, COOKIES.EXPIRATION_24H, COOKIES.DOMAIN);
    }

    return () => {
      window.removeEventListener('mousemove', changeUserInteraction);
      window.removeEventListener('scroll', changeUserInteraction);
      window.removeEventListener('touchstart', changeUserInteraction);
    };
  }, [voucher]);

  const sendAndEmptyLabels = () => {
    labelsExecutor([...labelsToSend.current], () => {
      sendingLabels = false;
      labelsToSend.current = [];
      document.removeEventListener('visibilitychange', sendAndEmptyLabels);
    });
  };

  const prepareAndSendI18n = (parent, anchor, sendLocale) => {
    if (process.browser) {
      const i18nString = `${i18nPage}${sendLocale ? ` ${locale} ` : ' '}${parent} ${anchor}`;
      if (!sentLabels.current.includes(i18nString)) {
        labelsToSend.current.push(i18nString);
        sentLabels.current.push(i18nString);
        labelsToSend.current.sort();

        delayFunction(() => {
          // esperamos a que se hayan rellenado
          // como tenemos que llamarlo aquí
          // hacemos el check de sending para solo mandar labels una vez

          if (labelsToSend.current.length !== 0 && sendingLabels === false) {
            sendingLabels = true;
            sendAndEmptyLabels();
            document.addEventListener('visibilitychange', sendAndEmptyLabels);
          }
        }, 10000);
      }
    }
  };

  /**
   * @function wrappedT
   * @param {string} parent
   * @param {string} anchor
   * @param {string} defaultValue
   * @returns
   */
  const wrappedT = (parent, anchor, defaultValue) => {
    // preparamos para registrar labels
    // las mandaremos a un nuevo endpoint
    if (i18nPage) {
      const translationResult = newT(labels, parent, anchor);
      if (translationResult.foundKey !== null) {
        return translationResult.foundKey;
      }

      // de momento lo mandamos solo cuando foundKey es null para el nuevo
      prepareAndSendI18n(parent, anchor, false);
      return translationResult.defaultReturn;
    }

    // en el futuro también tendremos el modo de siempre en el que acumulamos y mandamos todas
    // prepareAndSendI18n(parent, anchor, true);

    return t(locale, labels, parent, anchor, defaultValue);
  };

  useEffect(() => {
    const tpvStore = getCookie(COOKIES.TPV_STORE_ID);

    if (tpvStore && appmode) {
      setCookie(COOKIES.STORE, tpvStore);
    }
  }, [appmode]);

  const additionalGlobalScripts = [];

  const handleTrackables = () => {
    const trackablesTypesData = {};
    const eventItems = [];
    const lazyTrackables = [].slice.call(document.querySelectorAll(`[data-tracking*="${CAMPAIGN_ID}"]:not(.tnm__link)`));

    lazyTrackables.forEach((trackable) => {
      const trackedTargetData = trackable?.dataset?.tracking?.split('|');

      if (trackedTargetData?.length > 3) {
        const promotionId = trackedTargetData[2];
        const trackablesTypeList = [].slice.call(document.querySelectorAll(`[data-tracking*="|${trackedTargetData[3]}"]:not(.tnm__link)`));

        trackablesTypeList.forEach((item) => {
          const trackableData = item.dataset.tracking.split('|');
          const [, , id, creativeType] = trackableData;

          if (id && creativeType) {
            if (Object.keys(trackablesTypesData).includes(creativeType)) {
              if (!trackablesTypesData[creativeType].includes(id)) {
                trackablesTypesData[creativeType].push(id);
              }
            } else {
              trackablesTypesData[creativeType] = [id];
            }
          }
        });

        const alreadyInItems = eventItems.filter((item) => item.promotion_id === promotionId).length > 0;
        const gtmType = i18nPage || '';
        window.gtmType = gtmType;

        if (!alreadyInItems) {
          const creativeTypeList = trackablesTypesData[trackedTargetData[3]];

          if (creativeTypeList === undefined) {
            console.error('Bad tracking:', trackedTargetData);
          } else {
            let creativeSlot = creativeTypeList.indexOf(promotionId);

            if (creativeSlot !== -1) {
              creativeSlot = creativeSlot + 1;
            }

            eventItems.push({
              location_id: gtmType,
              promotion_id: promotionId,
              promotion_name: trackedTargetData[2],
              creative_name: trackedTargetData[3],
              creative_slot: creativeSlot,
              index: eventItems.length + 1,
            });
          }
        }
      }
    });

    if (eventItems.length > 0) {
      const eventGA4 = {
        event: 'view_promotion',
        ecommerce: {
          items: eventItems,
        },
      };

      handleStorage('setItem', 'gtmPromotionItems', JSON.stringify(eventItems));

      dataLayerHandleEvent(eventGA4);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', (event) => {
        try {
          if (event?.data) {
            const { ready, origin, type, payload } = event?.data || {};

            if (origin === 'roku' && ready === true) {
              handleTrackables();
            } else if (type === 'datalayerevent' && payload !== undefined) {
              dataLayerHandleEvent(payload);
            }
          }
        } catch (e) {
          console.error(e);
        }
      });
    }

    handleTrackables();
  }, []);

  return (
    // controlar provider user solo páginas que requieran
    <>
      <Head>
        <script id="lazyloadvideo-intersector">
          {process.browser && document.addEventListener('DOMContentLoaded', addLazyLoadIntersector(pageProps))}
          {process.browser && i18nPage === 'Landing' && document.addEventListener('DOMContentLoaded', addLazyLoadIntersectorLanding(pageProps))}
        </script>
        {additionalGlobalScripts.map((item) =>
          cloneElement(item, {
            id: `script-${item.key}`,
            name: `script-${item.key}`,
            async: item.props?.async || false,
            defer: item.props?.defer || false,
          }),
        )}
      </Head>
      <UserProvider autoappliedVoucher={autoappliedVoucher}>
        <CMSProvider language={getLang(locale)} country={getMarket(locale)} urlBase={urlBase}>
          <I18nProvider
            t={wrappedT}
            appmode={appmode}
            storeData={storeData}
            setStoreData={setStoreData}
            countries={countries}
            locale={locale}
            profileData={profile}
            detectedCountry={detectedCountry} // temporal cambio getHeader en cliente
            popupInfo={popupInfo}
            userInteracted={userInteracted}
            i18nPage={i18nPage}
            unbxdInitialSearch={unbxdInitialSearch}
          >
            <ErrorProvider error={pageProps?.error || false}>
              <Component {...pageProps} autoappliedVoucher={autoappliedVoucher} />
              <GreatBritainModal />
              {IS_NNORMAL ?
                <StoresModal />
              : null}
            </ErrorProvider>
          </I18nProvider>
        </CMSProvider>
      </UserProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const { Component, router, ctx } = appContext;
  const { req, res } = ctx;
  const { locale } = router;
  const { labels, i18nPage } = Component;
  let labelsSet = new Set([]);
  let environment = 'production';
  let urlBase = IS_CAMPER ? 'https://camper.com' : 'https://www.nnormal.com/';

  try {
    environment = process.env.ENVIRONMENT;
    urlBase = process.env.INTEGRA_DOMAIN;
  } catch (error) {
    console.info('No se ha podido cargar el process.env, usando valores por defecto...');
  }

  try {
    // Setear la cookie COOKIES.CLIENT en Server
    if (req !== null && req !== undefined && req.url !== '/500') {
      // En el build falla, asi que lo ponemos en un try/catch
      const clientCookie = req.cookies && req.cookies !== undefined ? req.cookies[COOKIES.CLIENT] : null;

      if (!clientCookie) {
        const newID = UUID();
        nookies.set(ctx, COOKIES.CLIENT, newID, {
          path: '/',
        });

        if (req.cookies === undefined || req.cookies === null) {
          req.cookies = {};
        }
        req.cookies[COOKIES.CLIENT] = newID;
      }
    }
  } catch (error) {
    console.error(`Error recuperando / seteando la cookie ${COOKIES.CLIENT}`);
    console.error(error);
  }

  const appProps = await App.getInitialProps(appContext);
  let returnValue = {
    ...appProps,
    locale,
    urlBase,
    environment,
  };

  try {
    if (i18nPage) {
      const labelsResult = await getTranslationsNew({ context: ctx, profile: locale, page: i18nPage });

      returnValue = { ...returnValue, labels: labelsResult };
    } else {
      console.error(`No i18nPage for ${Component}`);
      // añadimos generico para que en caso de no tener labels pueda mostrar el texto
      labelsSet = new Set([...labelsSet, 'generico']);
      if (labels) {
        labelsSet = new Set([...labelsSet, ...labels]);
      }
      const result = await getTranslations({ context: ctx, profile: locale, relParents: [...labelsSet] });
      returnValue = { ...returnValue, labels: result };
    }

    const countries = await getCountries({ context: ctx, profile: locale });

    returnValue = { ...returnValue, countries };
  } catch (e) {
    console.error(e);
  }
  const { query } = ctx;
  const { voucher, prevoucher } = query;

  if (voucher) {
    returnValue = { ...returnValue, voucher };
  }

  if (prevoucher) {
    returnValue = { ...returnValue, prevoucher };
  }

  try {
    let extraProfileParams = {};

    if (i18nPage === 'grid') {
      const searchParams = router.query;
      const filters = {};
      const { target, product, brand } = searchParams;

      if (brand !== undefined) {
        filters['filter.lineCollection'] = brand.toUpperCase();
      }

      Object.keys(searchParams).forEach((searchKey) => {
        if (searchKey.startsWith('filter.')) {
          filters[searchKey] = searchParams[searchKey];
        }
      });

      const targetId = getRealID('target', target);
      const productId = getRealID('product', product);
      const encodedFilters = filterTransformer.encode(filters);

      extraProfileParams = {
        targetId,
        family: productId,
        page: 'PLP',
        filters: encodedFilters,
      };
    }

    if (i18nPage === 'ProductSheet') {
      const searchParams = router.query;
      const realTargetId = getRealID('target', searchParams.target);
      const camperCodeRegex = /camper-([^?]+)-([a-zA-Z0-9]{5,7}-[a-zA-Z0-9]{3,5}(-[a-zA-Z0-9]{4})?)/;
      const nnormalCodeRegex = /nnormal-([^?]+)-([a-zA-Z0-9]{5,7}-[a-zA-Z0-9]{3,5}(-[a-zA-Z0-9]{4})?)/;
      const codeRegex = IS_NNORMAL ? nnormalCodeRegex : camperCodeRegex;
      const match = codeRegex.exec(searchParams?.code?.toLowerCase());

      let productCode = null;

      if (match !== null) {
        // no matches found, no debería pasar
        [, , productCode] = match; // assign product code from regex
        productCode = productCode.toUpperCase();
      }

      extraProfileParams = {
        gender: realTargetId,
        page: 'PDP',
        material: productCode || searchParams.code,
      };
    }

    const profileData = await checkProfile({ context: ctx, profile: ['default', 'int', undefined].includes(locale) ? 'en_ES' : locale, ...extraProfileParams });

    if (['default', 'int'].includes(locale)) {
      if (ctx && ctx.res) {
        const urlSplitted = req.url.split('/');
        const pathname = urlSplitted.splice(2, urlSplitted.length);
        const redirectUrl = `/${profileData.defaultProfile}/${pathname.join('/')}`;

        if (environment !== 'local' && res && res.writeHead && typeof res.writeHead === 'function') {
          // para evitar el error de WRITE AFTER END NEXT DEV
          const encodedRedirectUrl = encodeURI(redirectUrl);
          res.writeHead(307, { Location: encodedRedirectUrl });
          res.end();
        }
      }
    }

    returnValue = { ...returnValue, profile: profileData };
  } catch (e) {
    console.error(e);
  }

  try {
    // TODO: intentar deprecar esto
    // popup info
    if (locale && !['default', 'int'].includes(locale)) {
      const popupInfo = await getPopUpInfo({ context: ctx, profile: locale });

      if (popupInfo) {
        returnValue = { ...returnValue, popupInfo };
      }
    }
  } catch (e) {
    console.error(e);
  }

  // buscado general unbxd ya que patterson renderiza 3 searchtnm
  try {
    const lang = getLang(locale);
    const isoLanguage = getRealLanguage(lang);
    const unbxdUid = req.cookies[COOKIES.UNBXD_UID] || null;
    const { buscador } = returnValue?.labels;

    if (buscador) {
      const apiKey = buscador['unbxd.api.key'];
      const siteKey = buscador['unbxd.site.key'];
      const unbxdTranslations = [apiKey, siteKey];
      const results = await autosuggestUnbxd(isoLanguage, getMarket(locale), '*', unbxdTranslations, unbxdUid);

      returnValue.unbxdInitialSearch = results || null;
    }
  } catch (e) {
    console.error(e);
  }

  return returnValue;
};

MyApp.propTypes = {
  Component: any,
  pageProps: object,
  labels: oneOfType([array, object]),
  locale: string,
  countries: array,
  urlBase: string,
  environment: string,
  profile: object,
  voucher: string,
  prevoucher: string,
  popupInfo: object,
  storeDataProp: object,
  unbxdInitialSearch: object,
};

export default MyApp;
